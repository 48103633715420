import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: 'AIzaSyAs9YKePrqfm8pUvQrPhDaPCaOJpVgQTzY',
  authDomain: 'quza-it.firebaseapp.com',
  databaseURL: 'https://quza-it.firebaseio.com',
  projectId: 'quza-it',
  storageBucket: 'quza-it.appspot.com',
  messagingSenderId: '115940019266',
  appId: '1:115940019266:web:65d674930129e978d271db',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

//firebase.analytics()

export default firebase
