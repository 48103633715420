import React, { useContext, useState } from 'react'

import StoreContext from '~/context/StoreContext'
import { Button, DetailsText } from '../../../styles'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { Option, Select } from 'muicss/react'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const [quantity, setQuantity] = useState(item.quantity)

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null

  // const selectedOptions = item.variant.selectedOptions
  //   ? item.variant.selectedOptions.map(
  //       option => `${option.name}: ${option.value} `
  //     )
  //   : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
    updateLineItem(client, checkout.id, item.id, target.value)
  }

  const MobProduct = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 0.5fr;
    grid-template-rows: 1fr 1fr 1fr;

    .prodImage {
      grid-row: 1;
      grid-column: 1;
    }
    .prodPrice {
      margin: auto 0;
      grid-row: 1;
      grid-column: 2;
    }
    .prodTitle {
      grid-row: 2;
      grid-column: 1;
    }
    .prodQuantity {
      grid-row: 2;
      grid-column: 2;
    }
    .prodSku {
      grid-row: 3;
      grid-column: 1;
    }
    .prodRemove {
      grid-row: 3;
      grid-column: 2;
    }
  `

  const QuantityCon = styled.td`
    .mui-select {
      select {
        border: 1px solid var(--light-grey);
        height: 41px;
        margin-top: 2px;
        width: 52px;
        padding: 1px 15px;
        background-position: 32px center;
      }
      .mui-select__menu {
        height: 220px;
        margin-top: 40px;
        .mui--is-selected {
          color: white;
          background-color: var(--blue);
        }
      }
      .mui-select__menu > div {
        &:hover {
          color: white;
          background-color: var(--blue);
        }
      }
    }
  `

  return (
    <>
      {!isMobile && (
        <tr>
          <td className="productInfo">
            <a href={`/product/${item.variant.product.handle}/`}>
              <div className="tableImage" style={{ marginTop: '5px' }}>
                {variantImage}
              </div>
            </a>
            <DetailsText
              className="heading cartName"
              style={{ margin: 'auto 0' }}
            >
              {item.title}
              {`  `}
              {item.variant.title === !'Default Title'
                ? item.variant.title
                : ''}{' '}
              <br />
              SKU#: {item.variant.sku}
            </DetailsText>
          </td>
          <QuantityCon>
            <Select
              name="quantity"
              id="quantity"
              className="cart"
              defaultValue={quantity}
              onChange={handleQuantityChange}
            >
              <Option value="1" label="1" />
              <Option value="2" label="2" />
              <Option value="3" label="3" />
              <Option value="4" label="4" />
              <Option value="5" label="5" />
              <Option value="6" label="6" />
              <Option value="7" label="7" />
              <Option value="8" label="8" />
              <Option value="9" label="9" />
              <Option value="10" label="10" />
            </Select>
          </QuantityCon>
          <td>
            <DetailsText className="heading" style={{ margin: 0 }}>
              R{' '}
              {(quantity * item.variant.price)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              .00
            </DetailsText>
          </td>
          <td>
            <Button className="clear remove" onClick={handleRemove}>
              &#10005;
            </Button>
          </td>
        </tr>
      )}
      {isMobile && (
        <MobProduct>
          <div className="prodImage">
            <a href={`/product/${item.variant.product.handle}/`}>
              {variantImage}
            </a>
          </div>
          <div className="prodTitle">
            <DetailsText className="heading">
              {item.title}
              {`  `}
              {item.variant.title === !'Default Title'
                ? item.variant.title
                : ''}
            </DetailsText>
          </div>
          <div className="prodSku">
            <DetailsText>SKU#: {item.variant.sku}</DetailsText>
          </div>
          <div className="prodPrice">
            <DetailsText className="heading right">
              R {quantity * item.variant.price}.00
            </DetailsText>
          </div>
          <div className="prodQuanitity" style={{ justifySelf: 'flex-end' }}>
            <QuantityCon>
              <Select
                name="quantity"
                id="quantity"
                className="cartMob"
                style={{ marginTop: '-15px' }}
                defaultValue={quantity}
                onChange={handleQuantityChange}
              >
                <Option value="1" label="1" />
                <Option value="2" label="2" />
                <Option value="3" label="3" />
                <Option value="4" label="4" />
                <Option value="5" label="5" />
                <Option value="6" label="6" />
                <Option value="7" label="7" />
                <Option value="8" label="8" />
                <Option value="9" label="9" />
                <Option value="10" label="10" />
              </Select>
            </QuantityCon>
          </div>
          <div className="prodRemove">
            <DetailsText className="heading right" onClick={handleRemove}>
              Remove
            </DetailsText>
          </div>
        </MobProduct>
      )}
    </>
  )
}

export default LineItem
